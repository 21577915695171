.cardContainer {
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.AllVendorsTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.TotalAssetsCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #cfe2f3 !important;
}
.TotalAssetValueCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.MaintenanceCostCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.PurchaseValueTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.PurchasesTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.addAssetBtn {
  background-color: #fce5cd !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.addMiantenanceCostBtn {
  background-color: #c9daf8 !important ;
  color: rgba(0, 0, 0, 0.7) !important;
}
.exprtBtn {
  background-color: #e8e8e8 !important ;
  font-size: 0.85714286rem !important;
}
.addTransaction {
  background-color: #c9daf8 !important ;
  font-size: 0.85714286rem !important;
}

.vendorFormRow {
  display: flex;
  margin-top: 20px;
}

.titleVendorform {
  margin: 7px 30px;
  text-align: end;
  width: 20%;
  align-self: center;
}
.creatVTBtn {
  margin-left: 40px !important;
  font-size: 13px !important;
}
.cancelBtn {
  background-color: #f4cccc !important;
}
.submitBtn {
  background-color: #b6d7a8 !important;
}
.maintenanceCost{
  border-bottom: 1px solid #a7c777;
  text-align: center;
  cursor: pointer;
}
.maintenanceModalHeading{
  padding-bottom: 10px;
  border-bottom: 1px solid #a7c777;
}

@media only screen and (max-width: 767px) {
  .TotalAssetsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #cfe2f3 !important;
  }
  .TotalAssetValueCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #d9ead3 !important;
  }
  .MaintenanceCostCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #d9ead3 !important;
  }
  .addMiantenanceCostBtn {
    background-color: #c9daf8 !important;
    color: rgba(0, 0, 0, 0.7) !important;
    margin-bottom: 10px !important;
    margin-left: 40% !important;
  }
  .addAssetBtn {
    background-color: #fce5cd !important;
    color: rgba(0, 0, 0, 0.7) !important;
    margin-left: 40% !important;
  }
}
