.TabContainer {
  margin: 20px;
}

.TurnoverRow {
  display: flex;
  margin-top: 20px;
  margin-left: 50px;
  align-items: center;
}

.SubmitBtnRow {
  margin-top: 40px;
  margin-left: 25%;
}

.Level1 {
  background-color: #ffe599 !important;
  color: #22252f !important;
}

.Level2 {
  background-color: #cccccc !important;
  color: #22252f !important;
}

.Level3 {
  background-color: #f9cb9c !important;
  color: #22252f !important;
}

.submitBtn {
  background-color: #b6d7a8 !important;
}

.popupSubmitBtn {
  background-color: #cce2ff !important;
}

.announcementMsgRow {
  display: flex;
  margin-top: 20px;
}
.announcementMsgColorRow {
  display: flex;
  margin-top: 20px;
}

.announcementMsgTitle {
  margin: 7px 30px;
  text-align: end;
  width: 20%;
  align-self: center;
}

.ReplaceImg {
  font-size: 10px;
  margin-left: 5px;
}

.SettingsFormRow {
  display: flex;
  margin-top: 20px;
}

.SettingsFormTitle {
  margin: 7px 30px;
  text-align: end;
  width: 20%;
  align-self: center;
}

.driverAppAnnouncementTitleRow {
  display: flex;
  justify-content: space-between;
}

.driverAppAnnouncementForm {
  width: 30%;
}

@media only screen and (max-width: 767px) {
  .TurnoverRow {
    display: block;
    margin-top: 20px;
    margin-left: 10px;
    align-items: center;
  }
  .inputMobile {
    width: 30%;
  }
  .SubmitBtnRow {
    margin-top: 20px;
    margin-left: 40px;
  }
  .driverAppAnnouncementTitleRow {
    display: block;
  }
  .driverAppAnnouncementForm {
    width: auto;
  }
  .announcementMsgRow {
    display: block;
    margin-top: 20px;
  }
  .announcementMsgTitle {
    margin: 10px;
    text-align: start;
    width: auto;
    align-self: center;
  }
  .driverAppAnnouncementFormColor {
    width: auto;
  }
}
