.PayoutCardContainer {
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.AllPayoutsCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.VendorsPayoutCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.ExecutivePayoutCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #f4cccc !important;
}

.DriversPayoutTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.VendorsPayoutTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.ExecutivePayoutTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.exprtBtn {
  background-color: #e8e8e8 !important ;
  font-size: 0.85714286rem !important;
}

.exprtBtnRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.totalEarningsBtn {
  background-color: #c9daf8 !important ;
}

@media only screen and (max-width: 767px) {
  .AllPayoutsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #cfe2f3 !important;
  }
  .VendorsPayoutCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #fce5cd !important;
  }
  .ExecutivePayoutCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #c9daf8 !important;
  }
  .exprtBtnRow {
    display: block;
    justify-content: space-between;
  }
}
