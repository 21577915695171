.loginPageLayout {
  display: flex;
  height: 100vh;
}
.LeftHandSection {
  background-color: #a7c777;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
}
.RightHandSection {
  margin: 20px;
  height: 95vh;
  background-color: #f2f5ff;
  background-image: url('../../../images/bg_img.jpg');
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 20px;
  margin-left: 50vw;
  padding-top: 25vh;
  /* padding-bottom: 15vw; */
}
.imagecontainer {
  width: 450px;
  position: absolute !important;
  margin-top: 20vh;
  margin-left: 15vw;
}
.loginCardContainer {
  padding: 40px !important;
  width: 400px !important;
  border-radius: 20px !important;
  box-shadow: 0 0px 5px 5px #e4e9fe !important;
}
.loginFormInputField {
  border: none !important;
  background-color: #f2f5ff !important;
  border-radius: 20px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
}
.loginFormInputField:hover {
  box-shadow: 0 0px 5px 2px #e4e9fe !important;
}
.loginFormSubmitBtn {
  background-color: #b6d7a8 !important;
  margin-top: 20px !important;
  border-radius: 20px !important;
  width: 40% !important;
  font-size: 16px !important;
  box-shadow: 0 3px 5px 1px #e4e9fe !important;
}
@media only screen and (max-width: 767px) {
  .loginPageLayout {
    display: flex;
    height: 100vh;
  }
  .LeftHandSection {
    background-color: #a7c777;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vh;
  }
  .RightHandSection {
    margin: 10px;
    height: 80vh;
    background-color: #f2f5ff;
    background-image: url('../../../images/bg_img.jpg');
    background-repeat: no-repeat;
    background-position: left;
    border-radius: 20px;
    padding-top: 15vh;
  }
  .imagecontainer {
    width: 300px;
    position: relative !important;
    margin-top: 5vh;
    margin-left: 15vw;
    margin-bottom: 5vh;
  }
  .loginCardContainer {
    padding: 20px !important;
    width: 300px !important;
    border-radius: 20px !important;
    box-shadow: 0 0px 5px 5px #e4e9fe !important;
  }
}