.billingCardContainer {
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.TotalTransactionsCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 23% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.TotalRevenueCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 23% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.RefundedOrdersCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 24% !important;
  border-radius: 20px !important;
  background-color: #f4cccc !important;
}

.PendingPaymentCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 24% !important;
  border-radius: 20px !important;
  background-color: #f4cccc !important;
}

.TotalTransactionsTitle {
  font-size: 18px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.TotalRevenueTitle {
  font-size: 18px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.RefundedOrdersTitle {
  font-size: 18px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.PendingPaymentsTitle {
  font-size: 18px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.pendingPaymentsForm {
  margin: 7px 30px;
  align-self: center;
  width: 30%;
  text-align: end;
}

.createInvoiceBtn {
  background-color: #fce5cd !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.exprtBtn {
  background-color: #e8e8e8 !important ;
  font-size: 0.85714286rem !important;
}

.HeaderCardRow2 {
  margin-top: 0px !important;
}

.BillingTitleRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ui.teal.buttons.button {
  background-color: #b6d7a8 !important;
}


@media only screen and (max-width: 767px) {
  .TotalTransactionsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #cfe2f3 !important;
  }
  .TotalRevenueCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #d9ead3 !important;
  }
  .RefundedOrdersCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #fce5cd !important;
  }
  
  .PendingPaymentCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #ead1dc !important;
  }
  .BillingTitleRow {
    display: block;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
}