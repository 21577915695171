.headercardContainer {
    display: flex;
    padding-top: 5px;
    padding-bottom: 10px;
    justify-content: space-between;
  }
  
  .AllExecutivesTitle {
    font-size: 21px;
    font-weight: bold;
    color: #22252f;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  
  .AllExecutivesCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 32%!important;
    border-radius: 20px !important;
    background-color: #d9ead3!important;
  }
  .NewLeadsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 32%!important;
    border-radius: 20px !important;
    background-color: #f4cccc!important;
  }
  .ConversionCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 32%!important;
    border-radius: 20px !important;
    background-color: #d9ead3!important;
  }
  .NewLeadsTitle {
    font-size: 21px;
    font-weight: bold;
    color: #22252f;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  
  .ConversionTitle {
    font-size: 21px;
    font-weight: bold;
    color: #22252f;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  
  .NavTabs {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 30px;
    align-items: center;
  }
  .NavTabsTitle {
    font-size: 15px;
    padding: 7px 15px;
    border: 2px solid #e2e3e9;
    border-right-width: 0;
  }
  p:last-child {
    margin-bottom: auto !important;
  }
  .NavTabsTitle ~ .Active {
    background-color: #d9ead3;
  }
  
  .addExecutiveBtn {
    background-color: #fce5cd !important;
    color: rgba(0, 0, 0, 0.7) !important;
  }
  .exprtBtn {
    background-color: #e8e8e8 !important ;
    font-size: 0.85714286rem !important;
  }
  .addTransaction {
    background-color: #c9daf8 !important ;
    font-size: 0.85714286rem !important;
  }
  
  .executiveFormRow {
    display: flex;
    margin-top: 20px;
  }
  
  .titleExecutiveform {
    margin: 7px 30px;
    text-align: end;
    width: 20%;
    align-self: center;
  }
  .creatVTBtn {
    margin-left: 40px !important;
    font-size: 13px !important;
  }
  .cancelBtn {
    background-color: #f4cccc !important;
  }
  .submitBtn {
    background-color: #b6d7a8 !important;
  }
  .MEtableiss{
    height: -webkit-fill-available;
     overflow: overlay; 
     margin-bottom: 75px; 
     padding-right: 20px;
  }
  

  @media only screen and (max-width: 767px) {
    .AllExecutivesCard {
      box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
      width: 100%!important;
      border-radius: 20px !important;
      background-color: #cfe2f3!important;
    }
    .NewLeadsCard {
      box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
      width: 100%!important;
      border-radius: 20px !important;
      background-color: #fce5cd!important;
    }
    .ConversionCard {
      box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
      width: 100%!important;
      border-radius: 20px !important;
      background-color: #c9daf8!important;
    }
  }