.invoiceSetDefaultLayout {
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: #f5f6fa;
    padding-top: 30px;
    padding-bottom: 30px; 
  }
  .invoiceSetCardContainer{
    padding: 20px 20px;
    background-color: #ffffff;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    border-radius: 20px;
  }
  
.invoiceContainer{
  padding: 70px 50px;
  border: 1px solid #b6d7a8;
  border-radius: 10px;
}
.FieldsRow{
    display:  flex;
    margin-bottom: 20px;
}

.LabelText{
    font-size: 15px;
    width: 35%;
    margin-right: 30px;
    align-self: center;
    text-align: right;
    margin-bottom: 0;
}