.LevelImg {
  position: absolute !important;
  margin-left: -50px;
  margin-top: 25%;
}

.ProfilePicSectionRow {
  display: flex;
  margin-left: 50px;
  justify-content: space-between;
}

.ActiveLabel {
  color: #b6d7a8 !important;
  border-color: #b6d7a8 !important;
}

.DetailListItems {
  padding: 10px !important;
}

.ui.vertical.tabular.menu .active.item {
  border-radius: 5px !important;
}

@page { margin-top: 30px; margin-bottom: 30px; size: A4 !important; } 

