.DashboardLayout {
  display: flex;
  height: 100vh;
  /* width: 100vw - 240px; */
}

.DashboardCardLayout {
  background-color: #f5f6fa;
  width: 70vw;
  padding: 20px;
  /* padding-right: 0px; */
  height: 100vh;
  border-radius: 20px;
}

.ProfileContainer {
  background-color: #fff;
  width: 18vw;
  padding: 20px 30px;
}

.ChartsRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.AllCustomersStatsiticsCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 21.9vw !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.AllMerchantsStatsiticsCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 21.9vw !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.AllExecutivesStatsiticsCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 21.9vw !important;
  border-radius: 20px !important;
  background-color: #f4cccc !important;
}

.DashboardOrdersReportCard {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
  width: 32.9vw !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  overflow: hidden;
}

.ProfileRow {
  display: flex;
  justify-content: space-between;
}

.TitleText {
  color: #22252f;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: 'Poppins';
}

.TitleTextSmall {
  color: #22252f;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: 'Poppins';
}

.SubTitleText {
  color: #727a90;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Poppins';
}
.descriptionText {
  color: #22252f;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins';
}

.DashboardReportHeaderRow {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.DashboardSalesReportCard {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
  width: 21.9vw !important;
  border-radius: 20px !important;
  box-shadow: none !important;
}

.DashboardRangeDropdown {
  min-width: 120px !important;
  background: #f5f6fa !important;
  color: #22252f !important;
  border-radius: 10px !important;
  margin-bottom: 20px;
}

.DashboardGrowthRow {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.Dashboardmargin0 {
  margin-bottom: 0px !important;
}

.trendingProductTitle {
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

.dashboardStepsLayout {
  border-radius: 20px !important;
  overflow: hidden;
  border: none !important;
  width: 67.5vw !important;
}

.dashboardStepsLayout:hover {
  box-shadow: 0 0px 5px 1px #d4d4d5, 0 0 0 0px #d4d4d5 !important;
}

.timer {
  font-size: 233px;
}

.ui.cards > .card {
  font-size: 1em;
  margin: 12px 5px;
}

@media only screen and (max-width: 767px) {
  .DashboardLayout {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 10px;
  }
  .DashboardCardLayout {
    background-color: #f5f6fa;
    width: 95vw;
    padding: 10px;
    height: auto;
    border-radius: 20px;
  }
  .AllCustomersStatsiticsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #cfe2f3 !important;
  }
  .AllMerchantsStatsiticsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #fce5cd !important;
  }
  .AllExecutivesStatsiticsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #c9daf8 !important;
  }
  .DashboardOrdersReportCard {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
    width: 100% !important;
    border-radius: 20px !important;
    box-shadow: none !important;
    overflow: hidden;
  }
  .DashboardRangeDropdown {
    min-width: 100px !important;
    background: #f5f6fa !important;
    color: #22252f !important;
    border-radius: 10px !important;
    margin-bottom: 20px;
  }
  .DashboardSalesReportCard {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
    width: 350px !important;
    border-radius: 20px !important;
    box-shadow: none !important;
  }
  .dashboardStepsLayout {
    border-radius: 20px !important;
    overflow: hidden !important;
    border: none !important;
  }
  .ui.steps .step {
    border: none;
  }
  .DashboardGrowthRow {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
  }
}
