.CustomerReportCard {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
  width: 33% !important;
  border-radius: 20px !important;
}

.ReportHeaderRow {
  /* display: block; */
  justify-content: space-between;
  padding: 20px;
}
.ReportHeaderRowFlex {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}


.SalesReportCard {
 box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
 width: 48.5% !important;
 border-radius: 20px !important;
}

.OrdersReportCard {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
  width: 32% !important;
  border-radius: 20px !important;
 }

.FeedbackReportCard {
 box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
 width: 48.5% !important;
 border-radius: 20px !important;
}


.RangeDropdown {
  min-width: 150px !important;
    background: #f5f6fa !important;
    color: #22252f !important;
    border: none !important;
    border-radius: 10px !important;
    margin-bottom: 20px;
}

.GrowthRow {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.margin0 {
  margin-bottom: 0px!important;
}

.chatsRowContainer{
  display: flex !important;
}
@media only screen and (max-width: 767px) {
  
.chatsRowContainer{
  display: block !important;
}
.CustomerReportCard {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
  width: 100% !important;
  border-radius: 20px !important;
}
.ReportHeaderRow {
  /* display: block; */
  justify-content: space-between;
  padding: 20px;
}
.ReportHeaderRowFlex {
  display: block;
  justify-content: space-between;
  padding: 20px;
}
.SalesReportCard {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
  width: 100% !important;
  border-radius: 20px !important;
 }
 .OrdersReportCard {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
  width: 100% !important;
  border-radius: 20px !important;
 }
 
.FeedbackReportCard {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
  width: 100% !important;
  border-radius: 20px !important;
 }
 
}