.gridLayout {
  border: 1px solid #b6d7a8;
  border-radius: 15px;
}

.merchantHeader {
  display: flex;
  align-content: center;
}
.merchantContact {
  align-content: center;
  /* margin-left: 5vw; */
}
.merchantOrderHistory {
  align-content: center;
}

.merchantTitle {
  display: flex;
  justify-content: space-between;
}
