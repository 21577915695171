/* Custom Global Styles */
.DefaultLayout {
  background-color: #f5f6fa;
  border-radius: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  /* height: 100vh; */
}

.HeaderLayout {
  padding: 20px 20px;
}

.CardContainer {
  padding: 20px 20px;
  background-color: #ffffff;
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 20px;
  /* height: 60vh; */
}

.ReportLayout {
  background-color: #f5f6fa;
  border-radius: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.PageTitleRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.AddMemberRow {
  display: flex;
  justify-content: space-between;
}

.MembershipRow {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
}

.NavTabs {
  margin-bottom: 0px !important;
}

.CustomTableLayout {
  overflow: scroll;
  max-height: 700px;
  display: block;
}

.CardContainer:hover {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
}

/* Custom Global Styles */

/* Semantic UI components */

.ui.menu {
  width: fit-content;
  border: 0px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: 0 0px 0px 0 rgba(34, 36, 38, 0.15) !important;
  margin-bottom: 0px !important;
  border-radius: 12px !important;
}

.ui.menu .active.item {
  background-color: #d9ead3 !important;
  font-weight: bold !important;
}

.ui.pointing.menu .active.item:after {
  background-color: #d9ead3 !important;
}

.uiCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
}

.ui.card {
  overflow: visible;
}

.ui.icon.input > input {
  background-color: #f5f6fa !important;
  border-radius: 10px;
}

.ui.search .prompt {
  border-radius: 15px !important;
}

.uiSelectionDropdownNavbar {
  min-width: 180px !important;
  background: #f5f6fa !important;
  color: #22252f !important;
  border-radius: 10px !important;
}

.ui.selection.dropdown {
  border-radius: 10px !important;
}

.ui.selection.active.dropdown .menu {
  border-radius: 0 0 10px 10px;
}

.ui.segment {
  border-radius: 15px !important;
  box-shadow: none !important;
}

a.active.item {
  border-radius: 5px !important;
}

.ui.grid > .row {
  padding: 15px 20px !important;
}

.ui.grid > [class*='four wide'].column {
  width: 15% !important;
}

.ui.popup {
  border-radius: 10px !important;
}


.card.ui:hover {
  box-shadow: 0 0px 5px 1px #d4d4d5, 0 0 0 0px #d4d4d5 !important;
}

button.button.ui:hover {
  box-shadow: 0 0px 5px 1px #d4d4d5, 0 0 0 0px #d4d4d5 !important;
}

.ui.borderless.pointing.menu:hover {
  box-shadow: 0 0px 5px 1px #d4d4d5, 0 0 0 0px #d4d4d5 !important;
}

.ui.icon.input > input:hover {
  box-shadow: 0 0px 5px 1px #d4d4d5, 0 0 0 0px #d4d4d5 !important;
}

.uiSelectionDropdownNavbar:hover {
  box-shadow: 0 0px 5px 1px #d4d4d5, 0 0 0 0px #d4d4d5 !important;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text{
  color: rgb(17, 17, 17) !important;
  font-weight: 700;
}
input::placeholder{
  color: #000000 !important;
  font-weight: 700;
  opacity: 0.8;
}
.ui.input>input{
  color: #000000 !important;
  font-weight: 700
}
.ui.dropdown>.text{
  font-weight: 700;
}
.ui.celled.table tr td, .ui.celled.table tr th{
  white-space:nowrap !important;
}
a{
  cursor: pointer;
}
.ui.table{
  font-size: 16px !important;
}
.ui.selection.dropdown .menu>.item{
  font-size: 17px;
}
.ui.search.dropdown>.text{
  font-size: 17px;
}

/* Semantic UI components */

/* Mobile Responsive */

@media only screen and (max-width: 767px) {
  .DefaultLayout {
    background-color: #f5f6fa;
    border-radius: 20px;
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    height: auto;
  }

  .HeaderLayout {
    padding: 20px 10px;
  }

  .CardContainer {
    padding: 20px 10px;
    background-color: #ffffff;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    height: auto;
  }

  .ReportLayout {
    background-color: #f5f6fa;
    border-radius: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    height: auto;
  }

  .PageTitleRow {
    display: flex;
    justify-content: space-between;
    display: block;
    margin-bottom: 40px;
  }

  .CustomTableLayout {
    overflow-x: scroll;
    /* overflow-y: hidden; */
    /* max-height: 500px; */
    display: block;
  }

  .ui.borderless.pointing.menu {
    overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
  }

  .ui.button {
    font-size: 12px !important;
  }

  .ui.checkbox {
    margin-top: 5px;
  }

  a.active.item {
    padding: 10px !important;
  }
  a.item {
    padding: 10px !important;
  }
}

/* Mobile Responsive */

/* Webkit Scroll bar Mobile */
body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgb(170, 170, 170);
  transition: color 0.2s ease;
}

body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}

body ::-webkit-scrollbar {
  height: 5px !important;
  width: 5px !important;
}
/* Webkit Scroll bar Mobile */

/* React default styles */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
