.title {
  color: #22252f;
  padding-top: 20px;
  font-size: 16px;
  margin-bottom: 30px;
  font-family: 'Poppins';
}

.profileContainer {
  display: flex;
  margin-left: 15px;
  margin-top: 50px;
  padding-bottom: 20px;
}

.profilePicContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profileDetailsContainer {
  padding-left: 15px;
  padding-top: 5px;
}

.profileName {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #22252f;
  font-family: 'Poppins';
}

.workSpaceText {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #22252f;
  margin-left: 30px;
  font-family: 'Poppins';
}

.profileEmail {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #7d8599;
  margin-top: -10px;
  font-family: 'Poppins';
}

.workSpaceContainer {
  margin-top: 30px;
}

.SubTitleText {
  color: #727a90;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Poppins';
}

.GreenText {
  color: #64a15e;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins';
}
