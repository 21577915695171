.headercardContainer{
    display: flex;
    padding-top: 5px;
    padding-bottom: 10px;
    justify-content: space-between;
}
.AllDriversTitle{
    font-size: 21px;
    font-weight: bold;
    color: #22252f;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 10px;
}

.AllDriversCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 32% !important;
    border-radius: 20px !important;
    background-color: #f4cccc !important;
}
.PermanentDriversCard{
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 32% !important;
    border-radius: 20px !important;
    background-color:  #d9ead3 !important;
}
.PermanentDriversTitle{
    font-size: 21px;
    font-weight: bold;
    color: #22252f;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 10px;
}
.TempDriversCard{
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 32% !important;
    border-radius: 20px !important;
    background-color: #c9daf8 !important;
}
.TempDriversTitle{
    font-size: 21px;
    font-weight: bold;
    color: #22252f;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 10px;
}

/*  */
.addDriverBtn{
    background-color: #fce5cd !important;
    color: rgba(0,0,0,0.7) !important;
    
}
.exprtBtn{
    background-color: #e8e8e8 !important ;
    font-size: .85714286rem !important;
}
.addTransaction{
    background-color: #c9daf8 !important ;
    font-size: .85714286rem !important;
}

.driverFormRow{
    display: flex;
    margin-top: 20px;
}

.titleDriverform{
    margin: 7px 30px;
    text-align: end;
    width: 20%;
    align-self: center;
}
.creatVTBtn{
    margin-left: 40px !important;
    font-size: 13px !important;
}
.cancelBtn{
    background-color: #f4cccc !important;
}
.submitBtn{
    background-color: #b6d7a8 !important;
}


@media only screen and (max-width: 767px) { 
    .AllDriversCard {
        box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
        width: 100% !important;
        border-radius: 20px !important;
        background-color: #cfe2f3 !important;
    }
    .PermanentDriversCard{
        box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
        width: 100% !important;
        border-radius: 20px !important;
        background-color:  #fce5cd !important;
    }
    .TempDriversCard{
        box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
        width: 100% !important;
        border-radius: 20px !important;
        background-color: #c9daf8 !important;
    }
}