.navBarissue {
  margin-right: 50px !important;
}

.barssidebar {
  font-size: 17px !important;
  margin: 20px !important;
  width: 15% !important;
}

.powerOff {
  font-size: 20px !important;
  margin-top: 20px !important;
  /* width: 18% !important; */
  color: #e06666;
}

.powerOffMobile {
  font-size: 18px !important;
  margin-top: 20px !important;
  margin-left: -80px !important;
  color: #e06666;
}

.searchmembers {
  margin-top: 15px;
  margin-left: 10px;
  width: 15vw;
  display: grid;
}
.result {
  display: flex !important;
  justify-content: space-between;
}

.headingmobile {
  font-size: 18px;
  margin-top: 15px !important;
  margin-left: 45px !important;
  /* color: #a7c777; */
  font-family: poppins;
}
.newInvoice {
  margin-left: 5px;
  margin-top: 20px;
}

/* Mobile Sidebar Close Button */
.closeBtn {
  position: absolute;
  font-size: 20px !important;
  margin-top: 15px !important;
  margin-left: 130px !important;
  background-color: #f5f6fa;
  padding-left: 18px;
  padding-top: 5px;
  min-height: -webkit-fill-available;
  margin-bottom: 10px !important;
  padding-right: 25px;
  border-radius: 10px;
}

/* Master Search suggestions UI */
.ui.search>.results .result{
  padding: 5px 5px 0px 5px;
}


@media only screen and (max-width: 600px) {
  .navBarissue {
    margin-right: 0px !important;
  }
}

/* Notifucation label Styling */
.ui.floating.label {
  position: absolute;
  z-index: 100;
  top: 0.5em !important;
  left: 14% !important;
  margin: -0.8em 0 0 -1.5em !important;
}
