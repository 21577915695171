.customerCardContainer {
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.AllCustomersCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.CustomersSalesCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.CustomersOrdersCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #f4cccc !important;
}

.AllCustomersTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.CustomersSalesTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.CustomersOrdersTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.addCustomerBtn {
  background-color: #fce5cd !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.exprtBtn {
  background-color: #e8e8e8 !important ;
  font-size: 0.85714286rem !important;
}

.customerFormRow {
  display: flex;
  margin-top: 20px;
}

.customerFormTitle {
  margin: 7px 30px;
  text-align: end;
  width: 20%;
  align-self: center;
}

.addCustomerCancelBtn {
  background-color: #f4cccc !important;
}
.addCustomersubmitBtn {
  background-color: #b6d7a8 !important;
}

@media only screen and (max-width: 767px) {
  .AllCustomersCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #cfe2f3 !important;
  }
  .CustomersSalesCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #fce5cd !important;
  }
  .CustomersOrdersCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #c9daf8 !important;
  }
}
