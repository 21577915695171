.WBTitleRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.WBTextRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.WBColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.WBColumnText {
    margin-bottom: 10px;
}
  
@page { margin-top: 30px; margin-bottom: 30px; size: A4 Portrait; }