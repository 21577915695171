.LabelColorGreen {
  background-color: #93c47d !important;
  color: #fff !important;
}
.LabelColorRed {
  background-color: #e06666 !important;
  color: #fff !important;
}
.LabelColorYellow {
  background-color: #ffd966 !important;
  color: #fff !important;
}
.LabelColorBlue {
  background-color: #6d9eeb !important;
  color: #fff !important;
}
.LabelColorOrange {
  background-color: #f6b26b !important;
  color: #fff !important;
}
.modal-container {
  padding: 20px 50px;
}
.orderdetailsheading {
  padding-bottom: 10px;
  border-bottom: 1px solid #a7c777;
}
.orderId {
  border-bottom: 1px solid #a7c777;
  text-align: center;
  cursor: pointer;
}
.OrdersDefaultLayout {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #f5f6fa;
  height: 115vh;
}
.OrdersCardContainer {
  padding: 20px 20px;
  background-color: #ffffff;
  margin-left: 20px;
  margin-right: 20px;
  height: 80vh;
  border-radius: 20px;
}

.OrdersCardContainer:hover {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
}

.TotalCustomersCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 24% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}

.TotalCustomersTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}
.TotalMerchantsCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 24% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.TotalMerchantsTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.TotalVendorsCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 24% !important;
  border-radius: 20px !important;
  background-color: #f4cccc !important;
}
.TotalVendorsTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}
.TotalDriversCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 23% !important;
  border-radius: 20px !important;
  background-color: #f4cccc !important;
}
.TotalDriversTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}
.AllOrdersCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #d9ead3 !important;
}
.AllOrdersTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}
.ActiveOrdersCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #cfe2f3 !important;
}
.ActiveOrdersTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

.CompletedOrdersCard {
  box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
  width: 32% !important;
  border-radius: 20px !important;
  background-color: #fce5cd !important;
}
.CompletedOrdersTitle {
  font-size: 21px;
  font-weight: bold;
  color: #22252f;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}

/* INVOICE */

.invoicePageTitleRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.generateSaveBtn {
  background-color: #d9ead3 !important;
  color: rgba(0, 0, 0, 0.7) !important;
  font-size: 13px !important;
}
.cancelBtn {
  background-color: #f4cccc !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.cancelRowBtn {
  position: absolute;
  right: 85px;
}
.PDFBtn {
  background-color: #e0e1e2 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.smsBtn {
  background-color: #cfe2f3 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.mailBtn {
  background-color: #fce5cd !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.invoiceSetBtn {
  background-color: #d9ead3 !important;
  color: rgba(0, 0, 0, 0.7) !important;
  font-size: 13px !important;
}

.invoiceDefaultLayout {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #f5f6fa;
  padding-top: 30px;
  padding-bottom: 30px;
}
.invoiceCardContainer {
  padding: 20px 20px;
  background-color: #ffffff;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px;
}

.invoiceContainer {
  padding: 70px 50px;
  border: 1px solid #b6d7a8;
  border-radius: 10px;
}

.invoicePdfContainer {
  padding: 70px 50px;
  border: 1px solid #b6d7a8;
  border-radius: 10px;
  background-color: #fff;
  width: 240mm;
  min-height: 350mm;
  margin: auto;
  size: 0;
}

@page { margin-top: 30px; margin-bottom: 30px; size: A5 Portrait; }

.section1 {
  display: flex;
  justify-content: space-between;
}
.section1Text {
  margin-bottom: 0px;
}
.section1TextP{
  margin-bottom: 0px;
  font-size: 10px;
}
.section2 {
  background-color: #eee;
  padding: 5px 10px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.section2Text {
  margin-top: 10px;
  margin-bottom: 10px;
}
.section2P {
  background-color: #eee;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.section2TextP {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 10px;
}
.section3 {
  margin-top: 50px;
}
.section3Text {
  margin-bottom: 0px;
  text-align: right;
}
.section3TextP {
  margin-bottom: 0px;
  text-align: right;
  font-size: 10px;
}
.section4 {
  margin-top: 50px;
}
.section4P {
  margin-top: 10px;
}
.tableCellbg {
  background-color: #f9fafb;
  font-weight: 700;
}
.tableCellbgP {
  background-color: #f9fafb;
  font-weight: 700;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.tableCell{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.section5 {
  margin-top: 50px;
}
.driverDetails {
  margin-bottom: 5px;
}
.driverDetailsText {
  font-size: 15px;
  font-weight: 600;
}
.driverChallanTextH{
  font-size: 15px;
}
.driverChallanText{
  font-size: 12px;
}
.datepickerinput {
  padding: 0.67857143em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
}
.datepickerinput:focus {
  border-color: #85b7d9 !important;
}
.selectionHeading{
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 8px;
}
.mb-15{
  margin-bottom: 15px !important;
}
.selectionMerchantPopUpContainer{
  text-align: center;
  width: 250px;
}
.selectionProductDropdownWidth{
  width: 300px !important;
}

@media only screen and (max-width: 767px) {
  .TotalCustomersCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #cfe2f3 !important;
  }
  .TotalMerchantsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #d0e0e3 !important;
  }

  .TotalVendorsCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #d9ead3 !important;
  }
  .TotalDriversCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #fff2cc !important;
  }
  .AllOrdersCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #fce5cd !important;
  }
  .ActiveOrdersCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #f4cccc !important;
  }
  .CompletedOrdersCard {
    box-shadow: 0 0px 0px 0 #d4d4d5, 0 0 0 0px #d4d4d5 !important;
    width: 100% !important;
    border-radius: 20px !important;
    background-color: #d9d2e9 !important;
  }
}
